import React from 'react';
// 컴포넌트
import BackHeader from '../component/BackHeader';
import { Layout } from '../component/Styled';
// 이미지
import enrollImg from '../../src/asset/img/enroll.svg';
import kakaoButton from '../../src/asset/img/kakaoButton.svg';

const Enrolintro = () => {
  return (
    <Layout>
      <BackHeader ment="회원가입 안내" />
      {/* <Box /> */}
      <div className="mt-20 align-center">
        <img width={'100%'} src={enrollImg} alt="이미지" />
      </div>
      <div
        className="text-center  mt-10
      ">
        <a href="https://pf.kakao.com/_LJGSxb" target="_blank" rel="noreferrer">
          <img width={'100%'} src={kakaoButton} alt="이미지" />
        </a>
      </div>
    </Layout>
  );
};

export default Enrolintro;
