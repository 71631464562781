import React from 'react';
import { useNavigate } from 'react-router-dom';
// 컴포넌트
import ImgComponent from '../component/ImgComponent';
import { Layout } from '../component/Styled';
// 이미지
import back from '../asset/img/ic/back.svg';
import blueArrow from '../asset/img/ic/blueArrow.svg';

const IdPwFind = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="mt-4">
        <img src={back} alt="뒤로가기" onClick={() => navigate(-1)} />
        <div className="text-center mt-56">
          <div className="mt-6 mb-2 text-28 font-semibold text-text1 tracking-[-0.56px]">
            아이디 비밀번호 찾기
          </div>
          <div className="text-20 text-text1 tracking-[-0.3px]">
            아이디/비밀번호를 잊으셨나요?
          </div>
          <div className="text-20 text-text1 tracking-[-0.3px]">
            일하루에 문의 주시면
          </div>
          <div className="text-20 text-text1 tracking-[-0.3px]">
            빠른 처리 도와드리겠습니다.
          </div>
          <div className="mt-6 text-18 font-semibold text-text10 tracking-[-0.5px]">
            <a href="tel:031-729-9171">일하루 전화하기 031-729-9171</a>
          </div>

          <div className="text-text3 text-14 text-text3 tracking-[-0.21px]">
            연락 가능시간: 주중 오전9시 부터 오후6시
          </div>
        </div>
        <div className="text-center mt-56">
          <div
            className="text-text4 cursor-pointer"
            onClick={() => navigate('/enrollintro')}>
            회원가입이 필요하다면?
            <br />
            <div className="flex justify-center">
              일하루 청소년 회원가입 안내 바로가기{' '}
              <ImgComponent className="mt-0.5" src={blueArrow} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IdPwFind;
